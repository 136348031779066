import Link from 'next/link'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { Logo } from '../Logo'
import styles from './DashboardHeader.module.scss'

export const DashboardHeader: React.FC = () => {
  const router = useRouter()
  const activePath = router.pathname
  const getClasses = (path: string): string => {
    return classnames(styles.headerNavListItem, { [styles.headerNavListItemActive]: path === activePath })
  }
  return <header className={styles.header}>
    <Link href='/'>
      <a className={styles.headerLogoWrapper}>
        <Logo className={styles.headerLogo} />
      </a>
    </Link>
    <nav className={styles.headerNav}>
      <ul className={styles.headerNavList}>
        <li className={getClasses('/app')}><Link href='/app'><a>dashboard</a></Link></li>
        <li className={getClasses('/app/settings')}><Link href='/app/settings'><a>settings</a></Link></li>
        <li className={getClasses('/logout')}><Link href='/logout'><a>logout</a></Link></li>
      </ul>
    </nav>
  </header>
}
