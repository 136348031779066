import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import styles from './Header.module.scss'
import { Logo } from '../Logo'

interface HeaderProps {
  hideNavigation?: boolean
}

export const Header: React.FC<HeaderProps> = ({ hideNavigation = false }) => {
  const router = useRouter()
  const activePath = router.pathname
  const getClasses = (path: string): string => {
    return classnames(styles.headerNavListItem, { [styles.headerNavListItemActive]: path === activePath })
  }
  return <header className={styles.header}>
    <Link href='/'>
      <a>
        <Logo className={styles.headerLogo} />
      </a>
    </Link>
    {!hideNavigation && <nav className={styles.headerNav}>
      <ul className={styles.headerNavList}>
        <li className={getClasses('/')}><Link href='/'><a>home</a></Link></li>
        <li className={getClasses('/privacy')}><Link href='/privacy'><a>privacy</a></Link></li>
        <li className={getClasses('/login')}><Link href='/login'><a>login</a></Link></li>
      </ul>
    </nav>}
  </header>
}
