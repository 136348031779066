import styles from './Typography.module.scss'

interface BodyProps {
  withHtml?: string
}
export const Body: React.FC<BodyProps> = ({ withHtml, children }) => {
  const contentProps = withHtml != null ? { dangerouslySetInnerHTML: { __html: withHtml } } : { children }
  return <p className={styles.body} {...contentProps} />
}

export const Small: React.FC = ({ children }) => {
  return <p className={styles.small}>{children}</p>
}

export enum TitleLevel {
  ONE = 'one',
  TWO = 'two',
  THREE = 'three',
}
interface TitleProps {
  level: TitleLevel
}

export const Title: React.FC<TitleProps> = ({ children, level }) => {
  return {
    [TitleLevel.ONE]: <h1 className={styles.title}>{children}</h1>,
    [TitleLevel.TWO]: <h2 className={styles.title}>{children}</h2>,
    [TitleLevel.THREE]: <h3 className={styles.title}>{children}</h3>
  }[level]
}
